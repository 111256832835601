import Vector2D from "./Vector2D.ts";

class Line {
    point1: Vector2D;
    point2: Vector2D;

    constructor(point1: Vector2D, point2: Vector2D) {
        this.point1 = point1
        this.point2 = point2
    }

    length() {
        return this.point1.copy().sub(this.point2).mag();
    }

    draw(context: any, color: string) {
        context.strokeStyle = color;
        context.lineWidth = 4

        context.beginPath();
        context.moveTo(this.point1.x, this.point1.y)
        context.lineTo(this.point2.x, this.point2.y)
        context.closePath();

        context.stroke()
    }

    onLine(point: Vector2D) {
        return point.copy().sub(this.point1).mag() + point.copy().sub(this.point2).mag() - this.length() < 1
    }

    closest(point: Vector2D) {
        let adjustedLine, adjustedPoint, projectionScalar;

        adjustedLine = this.point2.copy().sub(this.point1);
        adjustedPoint = point.copy().sub(this.point1);

        projectionScalar = Math.max(0, Math.min(Vector2D.dot(adjustedLine, adjustedPoint) / (adjustedLine.mag() ** 2), 1));
        
        return this.point1.copy().add(adjustedLine.mult(projectionScalar));
    }

    intersects(other: Line) {
        let x1, y1, x2, y2, b1, b2, x, y; 

        x1 = -(this.point2.y - this.point1.y);
        x2 = -(other.point2.y - other.point1.y);
        
        y1 = (this.point2.x - this.point1.x);
        y2 = (other.point2.x - other.point1.x);
        
        b1 = -(x1*this.point1.x + y1*this.point1.y);
        b2 = -(x2*other.point1.x + y2*other.point1.y);

        if (x1 !== 0) {
            y = -(b2*x1-b1*x2) / (y2*x1-y1*x2);
            x = (-b1-y1*y) / (x1);
        } else {
            y = -(b1*x2-b2*x1) / (y1*x2-y2*x1);
            x = (-b2-y2*y) / (x2);
        }

        let result = new Vector2D(x, y);

        if (!(this.onLine(result) && other.onLine(result))) { result = Vector2D.fill(NaN) }

        return result;
    }
}

export default Line;