import Paddle from './Paddle.ts';
import Ball from './Ball.ts';
import Line from './Line.ts';
import Vector2D from './Vector2D.ts';

class Game {
    playerSpeed: number;

    canvas: any;
    context: any;

    keys: Map<string, boolean>;

    ball: Ball;
    player1: Paddle;
    player2: Paddle;

    MILLISECONDS_PER_FRAME: number;
    MILLISECONDS_PER_PHYS: number;

    constructor(canvas: any, fps: number, pps: number, playerSpeed: number) {
        this.playerSpeed = playerSpeed;
        this.MILLISECONDS_PER_FRAME = 1000 / fps;
        this.MILLISECONDS_PER_PHYS = 1000 / pps;

        this.canvas = canvas;
        this.context = canvas.getContext("2d");


        this.keys = new Map<string, boolean>([])

        
        this.player1 = new Paddle(new Vector2D(25, 250), Vector2D.zero(), new Vector2D(10, 100))
        this.player2 = new Paddle(new Vector2D(725, 250), Vector2D.zero(), new Vector2D(10, 100))
        this.ball = new Ball(new Vector2D(canvas.width / 2, canvas.height / 2), new Vector2D(10, 0), 10)

        // User Input Detection
        document.body.addEventListener("keydown", (event) => this.onInput(event.key, true), false);
        document.body.addEventListener("keyup", (event) => this.onInput(event.key, false), false);
    }

    clear() {
        this.context.globalAlpha = 1.0;
        this.context.fillStyle = "rgb(0, 0, 0)"
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    onInput(key: string, pressed: boolean) {
        this.keys.set(key.toLowerCase(), pressed);

        [this.player1, this.player2].forEach((player, playerIndex) => {
            player.velocity.set(Vector2D.zero());
            [["w", "s"],["i","k"]][playerIndex].forEach((_key, _keyIndex) => {
                if (this.keys.get(_key)) {
                    player.velocity.add(new Vector2D(0, (_keyIndex * 2 - 1) * this.playerSpeed))
                }
            });
        })
    }

    updateGraphics() {
        this.clear()

        this.ball.draw(this.context)
        this.player1.draw(this.context)
        this.player2.draw(this.context)
    }

    updatePhysics() {
        //this.clear()
        this.player1.update(this.MILLISECONDS_PER_PHYS)
        this.player2.update(this.MILLISECONDS_PER_PHYS)
        this.ball.update(this.MILLISECONDS_PER_PHYS)

        this.player1.collisions(this.ball, this.MILLISECONDS_PER_PHYS, this.context);
        this.player2.collisions(this.ball, this.MILLISECONDS_PER_PHYS, this.context);

        let ended = this.ball.collisions(this.MILLISECONDS_PER_PHYS, this.canvas);
        if (ended) { console.log("ended") }
    }
}

export default Game;