class Vector2D {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
    
    set(other: Vector2D): Vector2D {
        this.x = other.x;
        this.y = other.y;
        return this;
    }

    add(other: Vector2D): Vector2D { 
        this.x += other.x;
        this.y += other.y;
        return this;
    }

    sub(other: Vector2D): Vector2D { 
        this.x -= other.x;
        this.y -= other.y;
        return this;
    }

    mult(other: Vector2D): Vector2D;
    mult(value: number): Vector2D;
    mult(value: Vector2D | number): Vector2D {
        const multiply = (typeof(value) === "object") ? value : Vector2D.fill(value);

        this.x *= multiply.x; 
        this.y *= multiply.y;
        return this;
    }

    div(other: Vector2D): Vector2D;
    div(value: number): Vector2D;
    div(value: Vector2D | number): Vector2D {
        const division = (typeof(value) === "object") ? value : Vector2D.fill(value);

        this.x /= division.x; 
        this.y /= division.y;
        return this;
    }

    mag(): number { return Math.sqrt(this.x**2 + this.y**2); }
    angle(): number { return Math.atan2(this.y, this.x); }
    unit(): Vector2D { return this.copy().div(Vector2D.fill(this.mag())) }

    copy(): Vector2D { return new Vector2D(this.x, this.y); }

    point(context: any, radius: number, color: string = "#00FF00"): void {
        context.fillStyle = color;

        context.beginPath();
        context.arc(this.x, this.y, radius, 0, Math.PI * 2, false);

        context.fill()
    }
    
    static fill(value: number) { return new Vector2D(value, value)}
    static one(): Vector2D { return new Vector2D(1, 1); }
    static zero(): Vector2D { return new Vector2D(0, 0); }
    static fromAngle(angle: number, magniude: number = 1) { return new Vector2D(Math.cos(angle) * magniude, Math.sin(angle) * magniude)}
    static dot(vector1: Vector2D, vector2: Vector2D): number { return vector1.x * vector2.x + vector1.y * vector2.y; }
    static line(context: any, origin: Vector2D, heading: Vector2D, color: string = "#FF0000"): void { 
        context.strokeStyle = color;
        context.lineWidth = 4

        context.beginPath();
        context.moveTo(origin.x, origin.y)
        context.lineTo(origin.x + heading.x, origin.y + heading.y)
        context.closePath();

        context.stroke()
    }
}

export default Vector2D