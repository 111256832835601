import Vector2D from "./Vector2D.ts"

class GamePiece {
    position: Vector2D
    velocity: Vector2D //px per second
    color: string

    constructor (position: Vector2D, velocity: Vector2D, color: string = "#ffffff") {
        this.position = position
        this.velocity = velocity
        this.color = color
    }

    update(time: number /* ms */): void {
        this.position.add(this.velocity.copy().mult(time / 100))
    }

    next(time: number): Vector2D {
        return this.position.copy().add(this.velocity.copy().mult(time / 100))
    }
}

export default GamePiece