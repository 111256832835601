import Ball from "./Ball.ts";
import GamePiece from "./GamePiece.ts"
import Line from "./Line.ts";
import Vector2D from "./Vector2D.ts"

class Paddle extends GamePiece {
    size: Vector2D;

    constructor(position: Vector2D, velocity: Vector2D = Vector2D.zero(), size: Vector2D) {
        super(position, velocity);

        this.size = size;
    }

    draw(context: any): void{
        context.fillStyle = this.color
        context.fillRect(this.position.x - this.size.x / 2, this.position.y - this.size.y / 2, this.size.x, this.size.y);
    }

    getCorners() {
        let top, bottom, right, left;
        
        top = this.position.x + this.size.x / 2;
        bottom = this.position.x - this.size.x / 2;
        right = this.position.y - this.size.y / 2;
        left = this.position.y + this.size.y / 2;

        return [
            new Vector2D(top, right),
            new Vector2D(top, left),
            new Vector2D(bottom, right),
            new Vector2D(bottom, left)
        ]
    }

    closestEdge(point: Vector2D){
        let [topRight, topLeft, bottomRight, bottomLeft] = this.getCorners();
        let topLine: Line, bottomLine: Line, leftLine: Line, rightLine: Line;

        topLine = new Line(topRight, topLeft);
        bottomLine = new Line(bottomLeft, bottomRight);
        leftLine = new Line(topLeft, bottomLeft);
        rightLine = new Line(topRight, bottomRight);

        let closestTop: Vector2D, closestBottom: Vector2D, closestLeft: Vector2D, closestRight: Vector2D;
        
        closestTop = topLine.closest(point);
        closestBottom = bottomLine.closest(point);
        closestLeft = leftLine.closest(point);
        closestRight = rightLine.closest(point);

        let closest = closestTop;

        [closestBottom, closestLeft, closestRight].forEach(sidePoint => {
            if (point.copy().sub(sidePoint).mag() < point.copy().sub(closest).mag()) { closest = sidePoint }
        });

        return closest;
    }

    collisions(ball: Ball, time: number, context: any) {
        let x = Math.min(Math.max(ball.position.x, this.position.x - this.size.x / 2), this.position.x + this.size.x / 2);
        let y = Math.min(Math.max(ball.position.y, this.position.y - this.size.y / 2), this.position.y + this.size.y / 2);

        let ballNextLine = new Line(ball.position, ball.next(time));

        let closest = this.closestEdge(ball.position);
        
        let closestPointOnBallNext = ballNextLine.closest(closest);
        
        // Credit to https://gamedev.stackexchange.com/questions/10911
        if (closest.copy().sub(closestPointOnBallNext).mag() <= ball.radius) {
            console.log(closest.copy().sub(closestPointOnBallNext).mag());
            //ball.position.set(closestPointOnBallNext.copy());
            let difference = new Vector2D(x, y).sub(closestPointOnBallNext);

            let c = -2 * (ball.velocity.x * difference.x + ball.velocity.y * difference.y) / (difference.x ** 2 + difference.y ** 2)
            
            //console.log(ball.velocity.mag())

            ball.velocity.add(new Vector2D(difference.x * c, difference.y * c).mult(1))

            ball.velocity.set(ball.velocity.unit().mult(Math.min(ball.velocity.mag(), 1000)))
        }
    }
}

export default Paddle;