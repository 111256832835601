import GamePiece from "./GamePiece.ts"
import Vector2D from "./Vector2D.ts"

class Ball extends GamePiece {
    radius: number;

    constructor(position: Vector2D, velocity: Vector2D = Vector2D.zero(), radius: number) {
        super(position, velocity);

        this.radius = radius;
    }

    draw(context: any): void {
        context.fillStyle = this.color;

        context.beginPath();
        context.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2, false);
        
        context.fill()
    }

    collisions(time: number, canvas: any) {
        if (this.next(time).y < this.radius || this.next(time).y > canvas.height - this.radius) {
            this.velocity.set(new Vector2D(this.velocity.x, this.velocity.y * -1))
        }

        if (this.next(time).x < -this.radius * 2 || this.next(time).x > canvas.width + this.radius * 2) {
            this.velocity.set(new Vector2D(this.velocity.x * -1, this.velocity.y))

            return true
        }

        return false
    }

}

export default Ball;