export default function About() {
    document.title = "About";

    return (
        <>
            <h1>What is this website?</h1>
            <p>Hello, I am Adam, and this is my hobby website. I made this website for my programming projects as a way to share what I have made. I hope you enjoy exploring it :&#41; </p>
            <p>This page was made with Github pages, here is the <a href="https://github.com/CubeToPlay/CubeToPlay.github.io">link</a> to this github page</p>
        </>
    )
}